



















































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ShowTypes } from '@/components/typeLibrary/prepareTypes';
import { namespaces } from '@/scripts/namespaces';
import { FETCH_TYPES_LIBRARY, GET_TYPE_LIBRARY } from '@/store/types';
import ManageType from '@/components/typeLibrary/ManageType.vue';

@Component({
  components: {
    ManageType,
  },
})
export default class TypeList extends Vue {
  // Actions
  @Action(FETCH_TYPES_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) fetchTypes: any;

  // Getters
  @Getter(GET_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) getTypes: any;

  // Data
  editMode = false;

  get prepareTypes() {
    if (!this.getTypes) return [];
    const types = ShowTypes(this.getTypes);
    return types;
  }

  created() {
    // load last state of view from local storage
    const viewMode = localStorage.getItem('typeLibraryViewMode') || 'false';
    if (viewMode === 'false') {
      this.editMode = false;
    } else {
      this.editMode = true;
    }
    const { workspaceId } = this.$route.params;
    this.fetchTypes(workspaceId);
  }

  get hasType() {
    if (this.prepareTypes && this.prepareTypes.length > 0) {
      return true;
    }
    return false;
  }

  switchMode() {
    this.editMode = !this.editMode;
    localStorage.setItem('typeLibraryViewMode', this.editMode.toString());
  }
}
