
































































































import { ADD_TYPE_LIBRARY, GET_TYPE_LIBRARY } from '@/store/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { namespaces } from '@/scripts/namespaces';
import { AllTypes } from '@/scripts/shareModels/types';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { TypeLibrary } from '@/store/typeLibrary/models';
import { MESSAGE_TYPE_REGEX, TYPE_LIBRARY_REGEX } from '@/scripts/shared';
import { isUsedReservedType, specialTypes } from './prepareTypes';

@Component
export default class AddBaseType extends Vue {
  @Prop({ default: false }) dialog: any;

  // Actions
  @Action(ADD_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) addType: any;

  // Getters
  @Getter(GET_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) getTypes: any;

  @Validations()
  validations = {
    name: {
      required,
      isUnique: (value: any) => {
        if (value) {
          return !this.isTypeNameUnique(value);
        }
        return false;
      },
      isReserved: (value: any) => {
        if (value) {
          return !isUsedReservedType(value);
        }
        return false;
      },
      validateText: (value: any) => TYPE_LIBRARY_REGEX.test(value),
    },
    type: {
      required,
    },
  };

  isTypeNameUnique(name: string) {
    const type = this.getTypes.find(
      (t: TypeLibrary) => t.name.toLowerCase() === name.trim().toLowerCase(),
    );
    return !!type;
  }

  get nameErrors() {
    const errors: any[] = [];

    if (!this.$v.name.$dirty) return errors;

    if (!this.$v.name.required) errors.push(this.$t('required_field'));
    if (!this.$v.name.isUnique) errors.push(this.$t('unique_type_name'));
    if (!this.$v.name.isReserved) errors.push(this.$t('reserved_name'));
    if (!this.$v.name.validateText) errors.push(this.$t('message_type_invalid'));

    return errors;
  }

  get typeErrors() {
    const errors: any[] = [];

    if (!this.$v.type.$dirty) return errors;
    if (!this.$v.type.required) errors.push(this.$t('required_field'));

    return errors;
  }

  // Data
  name = '';

  type = '';

  doc = '';

  valid = true;

  loadAdd = false;

  key = 'string';

  value = '';

  listType = '';

  listTypeErrors: string[] = [];

  valueErrors: string[] = [];

  reservedTypes = AllTypes

  // Computed

  get innerTypeSelection() {
    return specialTypes(this.reservedTypes);
  }

  get key_value_type() {
    if (this.type !== 'map') {
      return [];
    }
    return [this.key, this.value];
  }

  // Methods
  close() {
    this.resetForm();
    this.$emit('close');
  }

  resetForm() {
    this.name = '';
    this.type = '';
    this.doc = '';
    this.$v.$reset();
  }

  prepareFields() {
    this.$v.$touch();
    if (this.$v.$invalid) return false;
    // check if the type is selected from reserved or custom types
    const isReservedType = AllTypes.find((t) => t === this.type);
    if (!isReservedType) {
      // If the type is not from reserved types, need to find the id from custom type and
      // send the typeId to the DB
      const typeId = this.getTypes.find((t: TypeLibrary) => t.name === this.type).id;
      this.type = typeId;
    }

    if (this.type !== 'list') {
      this.listType = '';
    } else if (!this.listType) {
      this.listTypeErrors.push(this.$t('required_field').toString());
      return false;
    }
    if (this.type !== 'map') {
      this.key = '';
      this.value = '';
    } else if (!this.value) {
      this.valueErrors.push(this.$t('required_field').toString());
      return false;
    }

    this.listTypeErrors = [];
    this.valueErrors = [];

    return true;
  }

  async save() {
    if (!this.prepareFields()) {
      return;
    }
    this.loadAdd = true;

    if (!this.type || !this.name) return;
    const newType: TypeLibrary = {
      name: this.name,
      type: this.type,
      key_value_types: this.key_value_type,
      list_type: this.listType,
      doc: this.doc,
    };
    const { workspaceId } = this.$route.params;
    await this.addType({ workspaceId, newType });
    this.loadAdd = false;

    this.resetForm();
    this.close();
  }

  onChangeValue(value: any) {
    if (value) {
      this.valueErrors = [];
    }
  }

  onChangeListType(listType: any) {
    if (listType) {
      this.listTypeErrors = [];
    }
  }
}
