





















































import {
  GET_TYPE_LIBRARY, SET_ERROR, UPDATE_TYPE_LIBRARY,
} from '@/store/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { namespaces } from '@/scripts/namespaces';
import { ShowTypes } from '@/components/typeLibrary/prepareTypes';
import AddBaseType from '@/components/typeLibrary/AddBaseType.vue';
import DeleteType from '@/components/typeLibrary/DeleteType.vue';
import EditType from '@/components/typeLibrary/EditType.vue';

@Component({
  components: {
    AddBaseType,
    DeleteType,
    EditType,
  },
})
export default class ManageTypes extends Vue {
  @Prop({ default: false }) dialog: any;

  // Actions
  @Action(SET_ERROR, { namespace: namespaces.ERROR }) setError: any;

  @Action(UPDATE_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) updateType: any;

  // Getters
  @Getter(GET_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) getTypes: any;

  // Data
  addBaseTypeDialog = false;

  // Methods
  close() {
    this.$emit('close');
  }

  get prepareTypes() {
    if (!this.getTypes) return [];
    const types = ShowTypes(this.getTypes);
    return types;
  }

  get hasType() {
    return this.getTypes && this.getTypes.length > 0;
  }
}
